import axios from "axios";
import {settings} from "../config";
import {IOrder} from "../Outbound/IOrder";
export const updateAdminData = async (data:IOrder,user:any)=>{
    let token = user.user?.signInUserSession?.idToken.jwtToken;
    let freightItem = {
        availableBidId:data.id,
        purchaseOrderNumber:data.purchaseOrderNumber,
        tarpRequired:data.tarpRequired,
        orderNumber:data.orderID,
        orderDate:data.dateReq,
        shipToCity:data.city,
        shipState:data.state,
        hotFreight:data.hotLoad,
        openForBid: data.sendToBoard,
        shipStatus:data.shippingStatus,
        bookNowRate: data.bookNowRate};
    await axios.post(settings.baseUrl + 'AdminBidList/CreateUpdateFreightItem', freightItem,{ headers:{"Authorization":"Bearer " + token}})
};