import axios from "axios";
import {settings} from "../config";

export const sendInboundManagementDataRequest = (user:any) => {
    let token = user.user?.signInUserSession?.idToken.jwtToken;
    return axios({
        url: settings.baseUrl + 'AdminBidList/GetInboundBids',
        method: 'get',
        headers:{"Authorization":"Bearer " + token}
    }).then((response:any) => {
        console.log("response", response);
        return (response.data || []).map((data:any)=>{
            return {
                id:data.AvailableBidId,
                orderID:data.OrderNumber,
                dateReq:data.OrderDate,
                city:data.ShipFromCity,
                state:data.ShipFromProv,
                shipWeight:data.ShipWeight,
                hotLoad:data.HotFreight,
                mapLink: `https://maps.google.com/?q=${data.ShipFromCity},${data.ShipFromProv}`,
                miles: data.Distance,
                sendToBoard: data.OpenForBid,
                shippingStatus:data.ShipStatus,
                bookNowRate: data.BookNowRate,
                bidItems: data.bidItems,
                tarpRequired: data.TarpRequired,
                purchaseOrderNumber:data.PurchaseOrderNumber,
                isInbound: data.IsInbound,
                issueDate: data.IssueDate,
                dueDate: data.DueDate
            };
        });
    });
};