import axios from "axios";
import {settings} from "../config";

export const sendInboundGetRequest = (user:any) => {
    let token = user.user?.signInUserSession?.idToken.jwtToken;
    if(!token) return null;
    return axios({
        url: settings.baseUrl + 'AdminBidList/GetInboundFreightItems',
        method: 'get',
        headers:{"Authorization":"Bearer " + token}
    }).then((response:any) => {
        return (response.data || []).map((data:any)=>{
            return {
                id:data.availableBidId,
                orderID:data.orderNumber,
                dateReq:data.orderDate,
                city:data.shipFromCity,
                state:data.shipFromProv,
                shipWeight:data.shipWeight,
                hotLoad:data.hotFreight,
                mapLink: `https://maps.google.com/?q=${data.shipFromCity},${data.shipFromProv}`,
                miles: data.distance,
                sendToBoard: data.openForBid,
                shippingStatus:data.shipStatus,
                bookNowRate: data.bookNowRate,
                tarpRequired: data.tarpRequired,
                purchaseOrderNumber:data.purchaseOrderNumber,
                isInbound: data.isInbound,
                issueDate: data.issueDate,
                dueDate: data.dueDate
            };
        });
    });
};