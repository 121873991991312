import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonText, IonLabel, IonIcon} from '@ionic/react';
import AuctionsGrid from '../components/AuctionsGrid';
import './Auctions.css';
import  NavComponent  from "../components/NavComponent";
import {flame} from "ionicons/icons";
import React from "react";


const Auctions: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonToolbar>
            <IonTitle ><img src="assets/images/MADfreight-logo.png" alt="logo" /></IonTitle>  
            <NavComponent />
        </IonToolbar>   
        <IonItem>

            <IonLabel className="ion-text-wrap">

              <IonText color="secondary">
                  <p>BOOK NOW REQUIRES PROMPT SHIP</p>
                  <p><span style={{color:"orange"}}><IonIcon style={{color:'orange'}} icon={flame}></IonIcon>HOT LOAD:</span> "TRUCKS THAT REQUIRE IMMEDIATE SHIPMENT".</p>
                  <p><span  style={{color:"blue"}}><img src="assets/rain.svg" alt="tarp required" style={{width:"25px"}} />
                      <span className="p-column-title">TARP REQUIRED</span></span> "TRUCKS THAT REQUIRE A TARP" </p>
              </IonText>
            </IonLabel>
          </IonItem>
        <AuctionsGrid />
      </IonContent>
    </IonPage>
  );
};

export default Auctions;
