import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { home, list, personCircle } from 'ionicons/icons';
import Dashboard from './pages/Dashboard';
import Auctions from './pages/Auctions';
import Profile from './pages/Profile';
import AuctionsAdmin from './pages/AdminList';
import { UserProvider } from "./components/UserProvider";
import Auctionsbid from './pages/Auctionsbid';
import Auctionsbook from './pages/Auctionsbook';
import Auctionedit from './pages/Auctionedit';

//AWS Cognito
import Amplify, { Auth } from 'aws-amplify';
import {AmplifyAuthenticator, AmplifySignIn, AmplifySignOut, withAuthenticator} from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/app.css';
Amplify.configure(awsconfig);
  
const App: React.FC = () => {    
  return (  
   <>
    <AmplifySignIn headerText="MadFreight" slot="sign-in"></AmplifySignIn>    
    <UserProvider>
      <IonApp>
        <IonReactRouter>
            <IonRouterOutlet>
              <Route exact path="/admin-auctions">
                <AuctionsAdmin />
              </Route>
              <Route exact path="/dashboard">
                <Dashboard />
              </Route>
              <Route exact path="/auctions">
                <Auctions />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/book-now/:orderID" component={Auctionsbook}>
              </Route>
              <Route path="/bid/:orderID" component={Auctionsbid}>
              </Route>
              <Route path="/admin-auction-edit/:orderID" component={Auctionedit}>
              </Route>
              <Route exact path="/">
                <Redirect to="/auctions" />
              </Route>
            </IonRouterOutlet> 
        </IonReactRouter>
        <AmplifySignOut />
      </IonApp>
    </UserProvider>
   </>
  );
};

export default  withAuthenticator(App);
