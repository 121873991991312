import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonItem, IonLabel,IonText,IonButtons,IonButton } from '@ionic/react';
import AuctionsGrid from '../components/AuctionsGrid';
import './Dashboard.css';
import { useUser } from "../components/UserProvider";
import  NavComponent  from "../components/NavComponent";

const Dashboard: React.FC = () => {  
  return (
    <IonPage>      
      <IonContent fullscreen>
        <IonToolbar>
            <IonTitle ><img src="assets/images/MADfreight-logo.png" alt="logo" /></IonTitle>  
            <NavComponent />
        </IonToolbar>  
        <IonItem>
            <IonLabel className="ion-text-wrap">
              <IonText color="primary">
                <h2>Recent Wins / Needs Shipping</h2>
              </IonText>              
            </IonLabel>
          </IonItem>        
        <AuctionsGrid  />
        <IonItem>
            <IonLabel className="ion-text-wrap">
              <IonText color="primary">
                <h2>My Active Auctions</h2>
              </IonText>              
            </IonLabel>
          </IonItem>        
        <AuctionsGrid  />
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
