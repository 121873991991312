import React, {useEffect, useState} from "react";
import {IonButton} from "@ionic/react";
import {InboundAuctionsData} from "./InboundAuctionData";
import {AdminProps, InboundAdminProps} from "../Outbound/AdminProps";
import {useUser} from "../UserProvider";
import {sendInboundGetRequest} from "../hooks/sendInboundGetRequestHook";
import {IOrder} from "../Outbound/IOrder";

export const InboundAdminGrid: React.FC<InboundAdminProps> = ({show, editData})=>{
    const [data, setData] = useState<IOrder[]>([]);
    const user = useUser();
    let email = user.user?.attributes?.email;
    useEffect(() => {
        if(email){
            sendInboundGetRequest(user)?.then(data => setData(data));
        }
    }, [email]);
    const refresh = ()=>{
        sendInboundGetRequest(user)?.then(data => setData(data));
    };
    return <><IonButton onClick={() => refresh()}>Refresh</IonButton>
    {show && <div >
        <InboundAuctionsData data={data} editItem={(item)=>editData(item)} refreshList={refresh}/>
    </div>
    }</>;
};
