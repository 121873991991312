//import React, {useEffect, useState} from 'react';
import {  
  IonButtons,
  IonButton,
} from '@ionic/react'
import './DashboardGrid.css';
import { useUser } from "../components/UserProvider";
import { AmplifySignOut } from '@aws-amplify/ui-react';

const NavComponent: React.FC = () => {   
    const user = useUser();
    let userGroups:Array<string> = ["Default"];
    let isAdmin:boolean = false;  
    if((user)&&(user !== null)) {
        userGroups = user.groups;        
        if((userGroups instanceof Array)&&(userGroups.indexOf("Admin") !== -1)) {
            isAdmin = true;
        }    
    }

  return (
    <IonButtons slot="secondary">
        <IonButton color="primary" routerLink="/auctions">Auctions</IonButton>
        <IonButton color="primary" routerLink="/profile">Profile</IonButton>
        {isAdmin && (
        <IonButton color="primary" routerLink="/admin-auctions">Admin</IonButton>
        )}              
        <AmplifySignOut />
    </IonButtons>    
  );
};

export default NavComponent;
