import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import './AuctionsGrid.css';
import Auctionedit from "../pages/Auctionedit";
import {Dialog} from "primereact/dialog";
import {TabPanel, TabView} from "primereact/tabview";
import {useUser} from "./UserProvider";
import {AdminGrid} from "./Outbound/AdminGrid";
import {InboundAdminGrid} from "./Inbound/InboundAdminGrid";
import {AdminOfferGrid} from "./Outbound/AdminOfferGrid";
import {sendGetRequest} from "./hooks/sendGetRequestHook";
import {sendManagementDataRequest} from "./hooks/sendManagementDataRequestHook";
import {updateAdminData} from "./hooks/updateAdminDataHook";
import {ContainerProps} from "./Outbound/ContainerProps";
import {AdminInboundOfferGrid} from "./Inbound/AdminInboundOfferGrid";
import AuctionInboundedit from "../pages/AuctionInboundedit";
import {IOrder} from "./Outbound/IOrder";

const AuctionsMgmtGrid: React.FC<ContainerProps> = ({ name }) => {
    const user = useUser();
    let email = user.user?.attributes?.email;
    const [data, setData] = useState<IOrder[]>([]);
    const [offers,setOffers] = useState<any[]>([]);
    const [editDataItem, setEditDataItem] = useState<any>({});
    const [showForm, setShowForm] = useState<boolean>(false);
    const [showInboundForm, setShowInboundForm] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(true);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
      if(email){
        sendGetRequest(user)?.then(data => setData(data));
        sendManagementDataRequest(user).then(data=>setOffers(data))
      }

    }, [email]);
  const editData = (item:IOrder)=>{
    setEditDataItem(item);
    setShowForm(true);
  };
  const editInboundData = (item:IOrder)=>{
    setEditDataItem(item);
    setShowInboundForm(true);
  };
  const saveData = async (item:IOrder)=>{
    await updateAdminData(item,user);
    let dataItems = await sendGetRequest(user);
    setData([...dataItems]);
    setEditDataItem({});
    setShowForm(false);
  };
  const saveInboundData = async (item:IOrder)=>{
    await updateAdminData(item,user);
    let dataItems = await sendGetRequest(user);
    setData([...dataItems]);
    setEditDataItem({});
    setShowInboundForm(false);
  };
  const refresh = async ()=>{
    setShow(false);
    let dataItems = await sendGetRequest(user);
    setData([...dataItems]);
    let offerData = await sendManagementDataRequest(user);
    setOffers(offerData);
    setShow(true);
  };
  return (
      <>
        <Dialog header="Edit" visible={showForm} style={{ width: '50vw' }}  onHide={() => setShowForm(false)}>
          <Auctionedit order={editDataItem} onSave={saveData}/>
        </Dialog>
        <Dialog header="Edit Inbound" visible={showInboundForm} style={{ width: '50vw' }}  onHide={() => setShowInboundForm(false)}>
          <AuctionInboundedit order={editDataItem} onSave={saveInboundData}/>
        </Dialog>
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header="Outbound  Offers">
            <AdminOfferGrid show={show} data={offers} refresh={refresh} editData={editData} />
          </TabPanel>
          <TabPanel header="Inbound Offers">
            <AdminInboundOfferGrid show={show} editData={editInboundData} />
          </TabPanel>
          <TabPanel header="Outbound Manager">
            <AdminGrid show={show} data={data} refresh={refresh} editData={editData} />
          </TabPanel>
          <TabPanel header="Inbound Manager">
            <InboundAdminGrid show={show} editData={editInboundData} />
          </TabPanel>
        </TabView>
      </>
  );
};
export default AuctionsMgmtGrid;
