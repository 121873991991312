import React, {useEffect, useState} from "react";
import {IonButton} from "@ionic/react";
import {AdminProps, InboundAdminProps} from "../Outbound/AdminProps";
import { BidInboundData } from "./BidInboundData";
import {IOrder} from "../Outbound/IOrder";
import {useUser} from "../UserProvider";
import {sendInboundGetRequest} from "../hooks/sendInboundGetRequestHook";
import {sendInboundManagementDataRequest} from "../hooks/sendInboundManagementGetRequest";

export const AdminInboundOfferGrid: React.FC<InboundAdminProps> = ({show, editData})=>{
    const [data, setData] = useState<IOrder[]>([]);
    const user = useUser();
    let email = user.user?.attributes?.email;
    useEffect(() => {
        if(email){
            sendInboundManagementDataRequest(user)?.then(data => setData(data));
        }
    }, [email]);
    const refresh = ()=>{
        sendInboundManagementDataRequest(user)?.then(data => setData(data));
    };
    return <><IonButton onClick={() => refresh()}>Refresh</IonButton>
        {show && <div >
            <BidInboundData data={data} editItem={(item)=>editData(item)} refreshList={refresh}/>
        </div>
        }</>;
};
