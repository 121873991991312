import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonText, IonLabel,IonButton } from '@ionic/react';
import './Auctions.css';
import  NavComponent  from "../components/NavComponent";
import { RouteComponentProps } from 'react-router-dom';


interface AuctionProps extends RouteComponentProps<{
  orderID: string;
}> {}

const Auctionsbook: React.FC<AuctionProps> = ({match}) => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonToolbar>
            <IonTitle >Book Now!  (Order# {match.params.orderID})</IonTitle>  
            <NavComponent />
        </IonToolbar>   
        <IonItem>
            <IonLabel className="ion-text-wrap">
              <IonText color="primary">
                <h3>Please confirm you want to book this auction.</h3>                
              </IonText>              
            </IonLabel>            
          </IonItem>   
          <IonItem><IonButton color="success">Book Now!</IonButton></IonItem>               
      </IonContent>
    </IonPage>
  );
};

export default Auctionsbook;
