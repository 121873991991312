import React from "react";
import {AdminProps} from "./AdminProps";
import {IonButton} from "@ionic/react";
import {BidInboundData} from "../Inbound/BidInboundData";
import {BidData} from "./BidData";


export const AdminOfferGrid: React.FC<AdminProps> = ({show,data, refresh, editData})=>{
    return <><IonButton onClick={() => refresh()}>Refresh</IonButton>
        {show && <div >
            <BidData data={data} editItem={(item)=>editData(item)} refreshList={refresh}/>
        </div>
        }</>;
};
