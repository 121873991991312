import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonItem,IonLabel,IonText } from '@ionic/react';
import AuctionsMgmtGrid from '../components/AuctionsMgmtGrid';
import './Admin.css';
import  NavComponent  from "../components/NavComponent";


const AdminList: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonToolbar>
            <IonTitle ><img src="assets/images/MADfreight-logo.png" alt="logo" /></IonTitle> 
            <NavComponent />
        </IonToolbar>  
        <IonItem>
            <IonLabel className="ion-text-wrap">
              <IonText color="primary">
                <h2>Auctions Management</h2>
              </IonText>              
            </IonLabel>
          </IonItem> 
        <AuctionsMgmtGrid name="Manage Auctions" />
      </IonContent>
    </IonPage>
  );
};

export default AdminList;
