import axios from "axios";
import {settings} from "../config";

export const sendManagementDataRequest = (user:any) => {
    let token = user.user?.signInUserSession?.idToken.jwtToken;
    return axios({
        url: settings.baseUrl + 'AdminBidList/GetAdminBids',
        method: 'get',
        headers:{"Authorization":"Bearer " + token}
    }).then((response:any) => {
        console.log("response", response);
        return (response.data || []).map((data:any)=>{
            return {
                id:data.AvailableBidId,
                orderID:data.OrderNumber,
                dateReq:data.OrderDate,
                city:data.ShipToCity,
                state:data.ShipToProv,
                shipWeight:data.ShipWeight,
                hotLoad:data.HotFreight,
                tarpRequired:data.TarpRequired,
                mapLink: `https://maps.google.com/?q=${data.ShipToCity},${data.ShipToProv}`,
                miles: data.Distance,
                sendToBoard: data.OpenForBid,
                shippingStatus:data.ShipStatus,
                bookNowRate: data.BookNowRate,
                bidItems: data.bidItems,
                isBookNow: (data.bidItems || []).find((i:any)=> {
                    return i.IsBookNow
                })
            };
        });
    });
};