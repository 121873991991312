import React, {useEffect, useState} from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonLabel,
  IonCheckbox,
  IonList,
  IonButton, IonText
} from '@ionic/react';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import ExploreContainer from '../components/ExploreContainer';
import './Profile.css';
import  NavComponent  from "../components/NavComponent";
import axios from "axios";
import {useUser} from "../components/UserProvider";
import {TabPanel, TabView} from "primereact/tabview";
const baseUrl = 'https://api.madfreightapi.com/';
//const baseUrl = 'https://localhost:5001/';
const sendGetRequest = async (user:any) => {
  let email:string = user.user?.attributes?.email;
  let token = user.user?.signInUserSession?.idToken.jwtToken;
  return (await axios({
    url: baseUrl + 'AdminBidList/GetUserProfile/' + email,
    method: 'get',
    headers:{"Authorization":"Bearer " + token}
  })).data;
};
const saveProfile = (user:any, data:any) => {
  let email:string = user.user?.attributes?.email;
  data.userName = email;
  data.states = data.states?.join(',');
  data.inboundStates = data.inboundStates?.join(',');
  let token = user.user?.signInUserSession?.idToken.jwtToken;
  return axios.post(baseUrl + 'AdminBidList/SaveUserProfile',data,{ headers:{"Authorization":"Bearer " + token}}).then(response => {
    data.inboundStates = data['inboundStates']?.split(",");
  })
};
const Profile: React.FC = () => {
  const [notifications, setNotifications] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [preferences, setPreferences] = useState<any>({});
  const user = useUser();
  let email = user.user?.attributes?.email;

  useEffect(() => {
    if(email)
      sendGetRequest(user).then(data => {
        setupData(data);
      });
  }, [email]);
  const setupData = (data:any)=>{
    let pt = Object.assign({}, {...data},{states:data['states']?.split(","),inboundStates:data['inboundStates']?.split(",")});
    setPreferences({...pt})
  }
  const updateData = (key:string,value:any)=>{
    preferences[key] = value;
    setPreferences({...preferences})
  };
  const setPreferredState = (state:string, checked:boolean)=>{
    preferences['states'] =preferences['states'] || [];
    if(checked)
      preferences['states'].push(state);
    else
      preferences['states'].splice(preferences['states'].indexOf(state),1);
    setPreferences({...preferences})
  };
  const setPreferredInboundState = (state:string, checked:boolean)=>{
    preferences['inboundStates'] =preferences['inboundStates'] || [];
    if(checked)
      preferences['inboundStates'].push(state);
    else
      preferences['inboundStates'].splice(preferences['inboundStates'].indexOf(state),1);
    setPreferences({...preferences})
  };
  const hasCheck= (state:string) =>{
    if(preferences['states'])
      return preferences['states'].indexOf(state) > -1;
    return false;
  };
  const hasInboundCheck= (state:string) =>{
    if(preferences['inboundStates'])
      return preferences['inboundStates'].indexOf(state) > -1;
    return false;
  };
  const save = async ()=>{
    await saveProfile(user, {...preferences});
    setupData(await sendGetRequest(user));
  };
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonToolbar>
            <IonTitle ><img src="assets/images/MADfreight-logo.png" alt="logo" /></IonTitle>  
            <NavComponent />
        </IonToolbar>
        <IonButton className="ion-margin-top" type="submit" onClick={async () => await save()}>
          Save
        </IonButton>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Notifications</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
              <IonItem>
                <IonLabel>Email for Preferred States: </IonLabel>
                <IonCheckbox checked={preferences['notifications']} onIonChange={e => updateData('notifications',e.detail.checked)} />
              </IonItem>
          </IonCardContent>
          <IonCardContent>
            <IonItem>
              <IonLabel>Email for HOT LOADS: </IonLabel>
              <IonCheckbox checked={preferences['notificationHotLoad']} onIonChange={e => updateData('notificationHotLoad',e.detail.checked)} />
            </IonItem>
          </IonCardContent>
        </IonCard> 
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Preferred States:</IonCardTitle>
          </IonCardHeader>
          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header="Outbound States">
              <IonText color="primary">
                <p style={{fontWeight:700,textAlign:"center"}}>ALL OUTBOUND LOADS SHIP FROM MADISON, VA (22727) (Destination state listed).</p>
              </IonText>
              <IonCardContent>
                <IonList>
                  <IonItem>
                    <IonLabel>Connecticut</IonLabel>
                    <IonCheckbox slot="start"   checked={hasCheck('CT')} onIonChange={e => setPreferredState('CT',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Delaware</IonLabel>
                    <IonCheckbox slot="start"   checked={hasCheck('DE')} onIonChange={e => setPreferredState('DE',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Indiana</IonLabel>
                    <IonCheckbox slot="start"   checked={hasCheck('IN')} onIonChange={e => setPreferredState('IN',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Kentucky</IonLabel>
                    <IonCheckbox slot="start"   checked={hasCheck('KY')} onIonChange={e => setPreferredState('KY',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Maine</IonLabel>
                    <IonCheckbox slot="start"   checked={hasCheck('ME')} onIonChange={e => setPreferredState('ME',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Massachusetts</IonLabel>
                    <IonCheckbox slot="start"   checked={hasCheck('MA')} onIonChange={e => setPreferredState('MA',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Maryland</IonLabel>
                    <IonCheckbox slot="start"   checked={hasCheck('MD')} onIonChange={e => setPreferredState('MD',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>New Hampshire</IonLabel>
                    <IonCheckbox slot="start"  checked={hasCheck('NH')} onIonChange={e => setPreferredState('NH',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>New Jersey</IonLabel>
                    <IonCheckbox slot="start"  checked={hasCheck('NJ')} onIonChange={e => setPreferredState('NJ',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>New York</IonLabel>
                    <IonCheckbox slot="start"  checked={hasCheck('NY')} onIonChange={e => setPreferredState('NY',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>North Carolina</IonLabel>
                    <IonCheckbox slot="start"  checked={hasCheck('NC')} onIonChange={e => setPreferredState('NC',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Ohio</IonLabel>
                    <IonCheckbox slot="start"  checked={hasCheck('OH')} onIonChange={e => setPreferredState('OH',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Pennsylvania</IonLabel>
                    <IonCheckbox slot="start"  checked={hasCheck('PA')} onIonChange={e => setPreferredState('PA',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Rhode Island</IonLabel>
                    <IonCheckbox slot="start"  checked={hasCheck('RI')} onIonChange={e => setPreferredState('RI',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>South Carolina</IonLabel>
                    <IonCheckbox slot="start"  checked={hasCheck('SC')} onIonChange={e => setPreferredState('SC',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Tennessee</IonLabel>
                    <IonCheckbox slot="start"  checked={hasCheck('TN')} onIonChange={e => setPreferredState('TN',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Vermont</IonLabel>
                    <IonCheckbox slot="start" checked={hasCheck('VT')} onIonChange={e => setPreferredState('VT',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Virginia</IonLabel>
                    <IonCheckbox slot="start" checked={hasCheck('VA')} onIonChange={e => setPreferredState('VA',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Washington DC</IonLabel>
                    <IonCheckbox slot="start" checked={hasCheck('DC')} onIonChange={e => setPreferredState('DC',e.detail.checked)} />
                  </IonItem>
                  <IonItem>
                    <IonLabel>West Virginia</IonLabel>
                    <IonCheckbox slot="start" checked={hasCheck('WV')} onIonChange={e => setPreferredState('WV',e.detail.checked)}/>
                  </IonItem>
                </IonList>
              </IonCardContent>
            </TabPanel>
            <TabPanel header="Inbound  States">
              <IonText color="primary">
                <p style={{fontWeight:700,textAlign:"center"}}>ALL INBOUND LOADS SHIP TO MADISON, VA (22727). (Departure State Listed) </p>
              </IonText>
              <IonCardContent>
                <IonList>
                  <IonItem>
                    <IonLabel>Alabama</IonLabel>
                    <IonCheckbox slot="start"   checked={hasInboundCheck('AL')} onIonChange={e => setPreferredInboundState('AL',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Arizona</IonLabel>
                    <IonCheckbox slot="start"   checked={hasInboundCheck('AR')} onIonChange={e => setPreferredInboundState('AR',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Florida</IonLabel>
                    <IonCheckbox slot="start"   checked={hasInboundCheck('FL')} onIonChange={e => setPreferredInboundState('FL',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Georgia</IonLabel>
                    <IonCheckbox slot="start"   checked={hasInboundCheck('GA')} onIonChange={e => setPreferredInboundState('GA',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Kentucky</IonLabel>
                    <IonCheckbox slot="start"   checked={hasInboundCheck('KY')} onIonChange={e => setPreferredInboundState('KY',e.detail.checked)}/>
                  </IonItem>

                  <IonItem>
                    <IonLabel>Louisiana</IonLabel>
                    <IonCheckbox slot="start"   checked={hasInboundCheck('LA')} onIonChange={e => setPreferredInboundState('LA',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Mississippi</IonLabel>
                    <IonCheckbox slot="start"   checked={hasInboundCheck('MS')} onIonChange={e => setPreferredInboundState('MS',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>North Carolina</IonLabel>
                    <IonCheckbox slot="start"  checked={hasInboundCheck('NC')} onIonChange={e => setPreferredInboundState('NC',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Ohio</IonLabel>
                    <IonCheckbox slot="start"  checked={hasInboundCheck('OH')} onIonChange={e => setPreferredInboundState('OH',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Oklahoma</IonLabel>
                    <IonCheckbox slot="start"  checked={hasCheck('OK')} onIonChange={e => setPreferredState('OK',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Pennsylvania</IonLabel>
                    <IonCheckbox slot="start"  checked={hasInboundCheck('PA')} onIonChange={e => setPreferredInboundState('PA',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>South Carolina</IonLabel>
                    <IonCheckbox slot="start"  checked={hasCheck('SC')} onIonChange={e => setPreferredState('SC',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Tennessee</IonLabel>
                    <IonCheckbox slot="start"  checked={hasCheck('TN')} onIonChange={e => setPreferredState('TN',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Texas</IonLabel>
                    <IonCheckbox slot="start"  checked={hasCheck('TX')} onIonChange={e => setPreferredState('TX',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Virginia</IonLabel>
                    <IonCheckbox slot="start" checked={hasInboundCheck('VA')} onIonChange={e => setPreferredInboundState('VA',e.detail.checked)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel>West Virginia</IonLabel>
                    <IonCheckbox slot="start" checked={hasInboundCheck('WV')} onIonChange={e => setPreferredInboundState('WV',e.detail.checked)}/>
                  </IonItem>
                </IonList>
              </IonCardContent>
            </TabPanel>
          </TabView>

        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
