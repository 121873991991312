import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import {
    IonItem,
    IonLabel,
    IonButton,
    IonCheckbox
} from '@ionic/react';
import './Auctions.css';
import {IOrder} from "../components/Outbound/IOrder";

interface AuctionProps  {
    order :IOrder,
    onSave: (item:IOrder) =>void
}

const AuctionInboundedit: React.FC<AuctionProps> = ({order,onSave}) => {
    const addUpdateLoad= ()=>{
        onSave(order)
    };
    return (

        <div className="ion-padding">
            <IonItem>
                <IonLabel >Hot Load</IonLabel>
                <IonCheckbox defaultChecked={true} checked={order.hotLoad}  onClick={()=>order.hotLoad = !order.hotLoad}/>
            </IonItem>
            <IonItem>
                <IonLabel >Tarp Required</IonLabel>
                <IonCheckbox defaultChecked={true} checked={order.tarpRequired}  onClick={()=>order.tarpRequired = !order.tarpRequired}/>
            </IonItem>
            <IonItem>
                <IonLabel >Send to Board</IonLabel>
                <IonCheckbox defaultChecked={true}   checked={order.sendToBoard} onClick={()=>order.sendToBoard = !order.sendToBoard}/>
            </IonItem>
            <IonButton className="ion-margin-top" type="submit" expand="block" onClick={addUpdateLoad}>
                Save
            </IonButton>
        </div>

    );
};

export default AuctionInboundedit;