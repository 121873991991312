import React from "react";
import {IonButton} from "@ionic/react";
import {AuctionsData} from "./AuctionData";
import {AdminProps} from "./AdminProps";

export const AdminGrid: React.FC<AdminProps> = ({show,data, refresh, editData})=>{
    return <><IonButton onClick={() => refresh()}>Refresh</IonButton>
        {show && <div >
            <AuctionsData data={data} editItem={(item)=>editData(item)} refreshList={refresh}/>
        </div>
        }</>;
};