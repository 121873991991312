import axios from "axios";
import {settings} from "../config";
export const sendCreateNewBid = async (data:any,user:any) => {
    let token = user.user?.signInUserSession?.idToken.jwtToken;
    await  axios.post( settings.baseUrl + 'AdminBidList/CreateUserBid',{isBookNow:data.isBookNow,
        state: data.state,
        city:data.city,
        shipNumber:data.shipNumber,
        ordNumber:data.orderID,
        userId:user.user?.attributes?.email,
        userEmail:user.user?.attributes?.email,
        bidAmount:data.bookNowRate},{headers:{"Authorization":"Bearer " + token}})
};