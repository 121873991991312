import React from 'react';
import { Auth } from 'aws-amplify';

// Create a context that will hold the values that we are going to expose to our components.
// Don't worry about the `null` value. It's gonna be *instantly* overriden by the component below 
export const UserContext = React.createContext({});

// Create a "controller" component that will calculate all the data that we need to give to our
// components bellow via the `UserContext.Provider` component. This is where the Amplify will be
// mapped to a different interface, the one that we are going to expose to the rest of the app.
export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState({});
  const [groups, setGroups] = React.useState({});

  React.useEffect( () => {
      Auth.currentAuthenticatedUser()
          .then(user => {
            if(user.signInUserSession.idToken.payload['cognito:groups']) {
              setGroups( user.signInUserSession.idToken.payload['cognito:groups']);
            }          
            setUser(user);
          })
          .catch(() => {
            setUser({});
            setGroups({});
          });
  }, []);

  // Make sure to not force a re-render on the components that are reading these values,
  // unless the `user` value has changed. This is an optimisation that is mostly needed in cases
  // where the parent of the current component re-renders and thus the current component is forced
  // to re-render as well. If it does, we want to make sure to give the `UserContext.Provider` the 
  // same value as long as the user data is the same. If you have multiple other "controller"
  // components or Providers above this component, then this will be a performance booster.
  const values:any = React.useMemo(() => ({ user,groups }), [user,groups]);
  
  // Finally, return the interface that we want to expose to our other components
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abstracting them from
// the fact that we are using React's context, but we also skip some imports.
export const useUser = ():any => {
  //console.log(UserContext);
  const context = React.useContext(UserContext);

  if(context === undefined) {
    throw new Error('`useUser` hook must be used within a `UserProvider` component');
  }
  return context;
};