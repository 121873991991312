import axios from "axios";
import {settings} from "../config";
export const sendGetInboundUserItemsRequest = (user:any) => {
    let email:string = user.user?.attributes?.email;
    let token = user.user?.signInUserSession?.idToken.jwtToken;
    return axios({
        url: settings.baseUrl + 'AdminBidList/GetUserInboundFreightItems/' + email,
        method: 'get',
        headers:{"Authorization":"Bearer " + token}
    }).then(response => {
        return (response.data || []).map((data:any)=>{
            return {
                orderID:data.orderNumber,
                dateReq:data.orderDate,
                shipNumber:data.shipNumber,
                city:data.shipFromCity,
                state:data.shipFromProv,
                miles: data.distance,
                shipWeight:data.shipWeight,
                hotLoad:data.hotFreight,
                mapLink: `https://maps.google.com/?q=${data.shipFromCity},${data.shipFromProv}`,
                sendToBoard: data.openForBid,
                shippingStatus:data.shipStatus,
                bookNowRate: data.bookNowRate,
                bidAmount:data.bidAmount,
                tarpRequired: data.tarpRequired,
                purchaseOrderNumber:data.purchaseOrderNumber,
                isInbound: data.IsInbound,
                issueDate: data.IssueDate,
                dueDate: data.DueDate
            };
        });
    })
};