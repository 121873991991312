import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {IonGrid, IonRow, IonCol, IonButton, IonIcon, IonModal, IonContent, IonItem, IonText} from '@ionic/react';
import './AuctionsGrid.css';
import {map,flame} from "ionicons/icons";
import  moment from 'moment';
import CurrencyInput from "react-currency-input-field";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Calendar} from "primereact/calendar";
import {useUser} from "./UserProvider";
import {Button} from "primereact/button";
import {sendGetUserItemsRequest} from "./hooks/sendUserGetFreightItems";
import {sendGetRequest} from "./hooks/sendGetRequestHook";
import {sendCreateNewBid} from "./hooks/sendCreateNewBidHook";
import {IOrder} from "./Outbound/IOrder";
import {TabPanel, TabView} from "primereact/tabview";
import {sendGetInboundUserItemsRequest} from "./hooks/sendGetUserInboundItemsHook";

interface ContainerProps {

}
const AuctionsGrid: React.FC<ContainerProps> = ({  }) => {
    const user = useUser();
    let email = user.user?.attributes?.email;
    const [data, setData] = useState<IOrder[]>([]);
    const [inboundData, setInboundData] = useState<IOrder[]>([]);
    const [bidItem, setBidItem] = useState<any>({});
    const [showModal, setShowModal] = useState(false);
    const [show,setShow] = useState(true);
    const [showInboundModal, setShowInboundModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date|undefined>(undefined);
    const [globalFilter, setGlobalFilter] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if(email){
            sendGetUserItemsRequest(user).then(data => setData(data));
            sendGetInboundUserItemsRequest(user).then(data => setInboundData(data));
        }

    }, [email]);
    const  formatMoney = (number:any)=> {
        return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };
    let userGroups:Array<string> = ["Default"];
    let isAdmin:boolean = false;
    if((user)&&(user !== null)) {
        userGroups = user.groups;
        if((userGroups instanceof Array)&&(userGroups.indexOf("Admin") !== -1)) {
            isAdmin = true;
        }
    }
    const filterDate = (value:string, filter:any) => {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }
        return moment(value).format("M/D/YY") === formatDate(filter);
    };

    const formatDate = (date:Date) => {
        let month: number = (date.getMonth() + 1);
        let day: number = (date.getDate());
        let monthstr: string = (date.getMonth() + 1).toString();
        let daystr: string = (date.getDate()).toString();
        if (month < 10) {
            monthstr = '0' + month.toString();
        }
        if (day < 10) {
            daystr = '0' + day.toString();
        }
        return moment(date).format("M/D/YY")

    };
    const dt = useRef(null);
    const onDateChange = (e:any) => {
        let current:any = dt.current;
        if(current !== null){
            current.filter(e.value, 'dateReq', 'custom');
            setSelectedDate(e.value);
        }
    };
    const refresh = async()=>{
        setShow(false);
        let datares = await sendGetUserItemsRequest(user);
        setData([...datares]);

        let inbounddatares = await sendGetInboundUserItemsRequest(user);
        setInboundData([...inbounddatares]);
        setShowInboundModal(false);
        setShow(true);
    }
    const bookNow=async (item:any)=>{
        item.isBookNow = true;
        await sendCreateNewBid(item,user);
        await refresh();
    };
    const updateValue= (field:string, value:any)=>{
        bidItem.bookNowRate = value;
    };
    const bidNow=(item:any)=>{
        setBidItem(item);
        setShowModal(true);
    };
    const inboundBidNow=(item:any)=>{
        setBidItem(item);
        setShowInboundModal(true);
    };
    const saveBid=async ()=>{
        await sendCreateNewBid(bidItem,user);
        await refresh();
    };
    const dateBodyTemplate = (rowData:any) => {
        return (
            <>
                <span className="p-column-title">Date</span>{moment(rowData.dateReq).format("M/D/YY")}
            </>
        );
    };

    const dateDueTemplate = (rowData:any) => {
        return (
            <>
                <span className="p-column-title">Date</span>{moment(rowData.dateDue).format("M/D/YY")}
            </>
        );
    };
    const dateIssuedTemplate = (rowData:any) => {
        return (
            <>
                <span className="p-column-title">Date</span>{moment(rowData.dateIssued).format("M/D/YY")}
            </>
        );
    };

    const bidTemplate = (rowData:any) =>{
        return <><span className="p-column-title">BID</span>{rowData['bidAmount'] <= 0 && <IonButton onClick={()=>bidNow(rowData)} color="secondary">BID</IonButton>}
            {rowData['bidAmount'] > 0 && <span>{formatMoney(rowData['bidAmount'])}</span>}</>;
    };

    const mapTemplate = (rowData:any) =>{
        return <><span className="p-column-title">Map</span><IonButton href={rowData['mapLink']} target={"_blank"} color="secondary"><IonIcon icon={map}></IonIcon></IonButton></>
    };
    const orderTemplate = (rowData:any)=>{
        if(rowData.shipWeight > 40000)
            return <><span className="p-column-title">Order # </span><span style={{fontWeight:800}}>{rowData.orderID}</span></>
        return <><span className="p-column-title">Order # </span>{rowData.orderID}</>
    };
    const poTemplate = (rowData:any)=>{
        if(rowData.shipWeight > 40000)
            return <><span className="p-column-title">Order # </span><span style={{fontWeight:800}}>{rowData.purchaseOrderNumber}</span></>
        return <><span className="p-column-title">Order # </span>{rowData.purchaseOrderNumber}</>
    };
    const hotLoadTemplate = (rowData:any)=>{
        return <>
            {rowData.hotLoad && <IonIcon style={{color:'orange'}} icon={flame}></IonIcon>}</>
    };
    const tarpedTemplate = (rowData:any)=>{
        return <>
            {rowData.tarpRequired && <><img src="assets/rain.svg" alt="tarp required" style={{width:"25px"}} />
            <span className="p-column-title">Tarp Required</span></>}
            </>
    };
    const inboundBidTemplate = (rowData:any) =>{
        return <><span className="p-column-title">BID</span>{rowData['bidAmount'] <= 0 && <IonButton onClick={()=>inboundBidNow(rowData)} color="secondary">BID</IonButton>}
            {rowData['bidAmount'] > 0 && <span>{formatMoney(rowData['bidAmount'])}</span>}</>;
    };
    const cityTemplate = (rowData: any)=>{
        return <><span className="p-column-title">City</span>{rowData.city}</>
    };
    const stateTemplate = (rowData: any)=>{
        return <><span className="p-column-title">State</span>{rowData.state}</>
    };
    const milesTemplate = (rowData: any)=>{
        return <><span className="p-column-title">Miles</span>{rowData.miles}</>
    };
    const bookNowTemplate = (rowData:any) =>{
        return <><span  className="p-column-title">Book Now</span>{rowData['bidAmount']  <= 0 && <IonButton onClick={()=>bookNow(rowData)} color="success">{formatMoney(rowData['bookNowRate'])}</IonButton>}</>
    };
    const dateFilter = <Calendar value={selectedDate} onChange={onDateChange} dateFormat="mm/dd/yy" className="p-column-filter" placeholder="Registration Date"/>;
    const exportCSV = (selectionOnly:any) => {
        let item:any = dt.current;
        item.exportCSV({ selectionOnly });
    };
    const cols:any[]= [
        { title: "ORDER #", dataKey: "orderID" },
        { title: "CITY", dataKey: "city" },
        { title: "STATE", dataKey: "state" },
        { title: "HOT LOAD", dataKey: "hotLoad" },
        { title: "MILES", dataKey: "miles" },
        { title: "BOOK NOW RATE", dataKey: "bookNowRate" }
    ];
    const exportPdf = () => {
        const exportColumns = cols;
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc:any = new jsPDF.default("landscape", "px");
                doc.autoTable(exportColumns, data);
                doc.save('products.pdf');
            })
        })
    };

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            let dataMapped:any[] = data.map(i=>{
                return {orderID:i.orderID, city:i.city,state:i.state,hotLoad:i.hotLoad, miles:i.miles,bookNowRate:i.bookNowRate}
            });
            dataMapped.unshift({});
            const worksheet = xlsx.utils.json_to_sheet(dataMapped,{  skipHeader: true });
            xlsx.utils.sheet_add_aoa(worksheet, [["ORDER #","CITY","STATE","HOT LOAD","MILES","BOOK NOW RATE" ]]); //heading: array of arrays
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'products');
        });
    };

    const saveAsExcelFile = (buffer:any, fileName:any) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    };
    let header = (
        <div className="p-d-flex p-ai-center export-buttons">
            <Button type="button" icon="pi pi-file-o" onClick={() => exportCSV(false)} className="p-mr-2" data-pr-tooltip="CSV" />
            <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" />
        </div>
    );
    if(!isAdmin)
        header = (<></>);
  return (
      <>
          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
              <TabPanel header="Outbound  Bids">
                  <IonText color="primary">
                      <p style={{fontWeight:700,textAlign:"center"}}>ALL OUTBOUND LOADS SHIP FROM MADISON, VA (22727)</p>
                  </IonText>
                  <IonModal isOpen={showModal} cssClass='my-custom-class'>
                      <IonContent >
                          <p>Enter a Bid for order # {bidItem?.orderID} </p>
                          $ &nbsp;<CurrencyInput placeholder="Please enter a number"
                                                 defaultValue={bidItem?.bookNowRate || 0}
                                                 decimalsLimit={2}
                                                 onValueChange={(value, name) => {updateValue('bookNowRate',value)} } />
                          <IonButton onClick={() => setShowModal(false)}>Cancel</IonButton> <IonButton onClick={() =>saveBid()}>Save</IonButton>
                      </IonContent>
                  </IonModal>
                  <IonButton onClick={() => refresh()}>Refresh</IonButton>
                  <div className="datatable-responsive-demo">
                      {show && <div className="card">
                          <DataTable ref={dt} value={data} header={header} className={"p-datatable-responsive-demo"}  globalFilter={globalFilter} paginatorTemplate="CurrentPageReport RowsPerPageDropdown"
                                     currentPageReportTemplate="{totalRecords} total available" paginator>
                              <Column field="orderID" header="Order #" body={orderTemplate} sortable filter></Column>
                              <Column field="hotLoad" header="Hot Load" body={hotLoadTemplate} sortable></Column>
                              <Column field="tarpRequired" header="Tarped" body={tarpedTemplate} sortable></Column>
                              <Column field="dateReq" header="Order Date" sortable filter body={dateBodyTemplate} filterElement={dateFilter} filterFunction={filterDate}></Column>
                              <Column field="city" header="City" body={cityTemplate} sortable filter></Column>
                              <Column field="state" header="State" body={stateTemplate} sortable filter></Column>
                              <Column field="miles" header="Miles" body={milesTemplate} sortable filter></Column>
                              <Column field="mapLink" header="Map"  body={mapTemplate}></Column>
                              <Column field="bookNowRate" header="BOOK NOW" sortable filter body={bookNowTemplate} ></Column>
                              <Column field="bookNowRate" header="BID" sortable filter body={bidTemplate} ></Column>
                          </DataTable>
                      </div>}
                  </div>
              </TabPanel>
              <TabPanel header="Inbound  Bids">
                  <IonText color="primary">
                      <p style={{fontWeight:700,textAlign:"center"}}>ALL INBOUND LOADS SHIP TO MADISON, VA (22727).  <br/>A pickup number will be required for all bids upon confirmation.</p>
                  </IonText>
                  <IonModal isOpen={showInboundModal} cssClass='my-custom-class'>
                      <IonContent >
                          <p>Enter amount for PO # {bidItem?.purchaseOrderNumber} </p>
                          $ &nbsp;<CurrencyInput placeholder="Please enter a number"
                                                 defaultValue={bidItem?.bookNowRate || 0}
                                                 decimalsLimit={2}
                                                 onValueChange={(value, name) => {updateValue('bookNowRate',value)} } />
                          <IonButton onClick={() => setShowInboundModal(false)}>Cancel</IonButton> <IonButton onClick={() =>saveBid()}>Save</IonButton>
                      </IonContent>
                  </IonModal>
                  <IonButton onClick={() => refresh()}>Refresh</IonButton>
                  <div className="datatable-responsive-demo">
                      {show && <div className="card">
                          <DataTable ref={dt} value={inboundData} header={header} className={"p-datatable-responsive-demo"}
                                     globalFilter={globalFilter} paginatorTemplate="CurrentPageReport RowsPerPageDropdown"
                                     currentPageReportTemplate="{totalRecords} total available" paginator>
                              <Column field="purchaseOrderNumber" header="PO #" body={poTemplate} sortable filter></Column>
                              <Column field="hotLoad" header="Hot Load" body={hotLoadTemplate} sortable></Column>
                              <Column field="tarpRequired" header="Tarped" body={tarpedTemplate} sortable></Column>
                              <Column field="dueDate" header="Due Date" sortable filter body={dateDueTemplate} filterElement={dateFilter} filterFunction={filterDate}></Column>
                              <Column field="issuedDate" header="Issued" sortable filter body={dateIssuedTemplate} filterElement={dateFilter} filterFunction={filterDate}></Column>
                              <Column field="city" header="City" body={cityTemplate} sortable filter></Column>
                              <Column field="state" header="State" body={stateTemplate} sortable filter></Column>
                              <Column field="mapLink" header="Map"  body={mapTemplate}></Column>
                              <Column field="bookNowRate" header="BID" sortable filter body={inboundBidTemplate} ></Column>
                          </DataTable>
                      </div>}
                  </div>
              </TabPanel>
          </TabView>

      </>
  );
};

export default AuctionsGrid;
