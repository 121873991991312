import React, { useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonText, IonLabel,IonInput,IonButton,IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent } from '@ionic/react';
import './Auctions.css';
import  NavComponent  from "../components/NavComponent";
import { RouteComponentProps } from 'react-router-dom';

interface AuctionProps extends RouteComponentProps<{
  orderID: string;
}> {}

const Auctionsbid: React.FC<AuctionProps> = ({match}) => {
    const [bid, setBid] = useState<number>();
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonToolbar>
            <IonTitle >Bid (Order# {match.params.orderID})</IonTitle>  
            <NavComponent />
        </IonToolbar>   
        <IonCard>
          <IonCardHeader>
          </IonCardHeader>
          <IonCardContent>
            <h3>Enter your bid to participate in this auction.</h3>
            <IonInput type="number" value={bid} placeholder="Enter Bid" onIonChange={e => setBid(parseInt(e.detail.value!, 10))}></IonInput><br />
            <IonButton color="success">Submit Bid</IonButton>
            </IonCardContent>
        </IonCard>           
      </IonContent>
    </IonPage>
  );
};

export default Auctionsbid;
