import axios from "axios";
import {settings} from "../config";

export const sendGetRequest = (user:any) => {
    let token = user.user?.signInUserSession?.idToken.jwtToken;
    if(!token) return null;
    return axios({
        url: settings.baseUrl + 'AdminBidList/GetFreightItems',
        method: 'get',
        headers:{"Authorization":"Bearer " + token}
    }).then((response:any) => {
        return (response.data || []).map((data:any)=>{
            return {
                purchaseOrderNumber:data.purchaseOrderNumber,
                dueDate: data.dueDate,
                isInbound: data.isInbound,
                issueDate: data.issueDate,
                id:data.availableBidId,
                orderID:data.orderNumber,
                dateReq:data.orderDate,
                city:data.shipToCity,
                state:data.shipToProv,
                shipWeight:data.shipWeight,
                hotLoad:data.hotFreight,
                tarpRequired:data.tarpRequired,
                mapLink: `https://maps.google.com/?q=${data.shipToCity},${data.shipToProv}`,
                miles: data.distance,
                sendToBoard: data.openForBid,
                shippingStatus:data.shipStatus,
                bookNowRate: data.bookNowRate
            };
        });
    });
};