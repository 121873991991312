import React, {useRef, useState} from "react";
import {GridProps} from "../GridProps";
import moment from "moment";
import {IonButton, IonIcon} from "@ionic/react";
import {map, pencil} from "ionicons/icons";
import {Button} from "primereact/button";
import {Calendar} from "primereact/calendar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

export const  AuctionsData: React.FC<GridProps> =  ({data,editItem,refreshList})=>{
    const [selectedDate, setSelectedDate] = useState<Date|undefined>(undefined);
    const [globalFilter, setGlobalFilter] = useState('');
    const  formatMoney = (number:any)=> {
        return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };
    const filterDate = (value:string, filter:any) => {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }
        return moment(value).format("M/D/YY") === formatDate(filter);
    };

    const formatDate = (date:Date) => {
        let month: number = (date.getMonth() + 1);
        let day: number = (date.getDate());
        let monthstr: string = (date.getMonth() + 1).toString();
        let daystr: string = (date.getDate()).toString();
        if (month < 10) {
            monthstr = '0' + month.toString();
        }
        if (day < 10) {
            daystr = '0' + day.toString();
        }
        return moment(date).format("M/D/YY")

    };
    const dt = useRef(null);
    const onDateChange = (e:any) => {
        let current:any = dt.current;
        if(current !== null){
            current.filter(e.value, 'dateReq', 'custom');
            setSelectedDate(e.value);
        }
    };
    const actionsBody = (rowData:any) =>{
        return <>
            <IonButton  onClick={()=>editItem(rowData)}><IonIcon icon={pencil}></IonIcon></IonButton>
        </>
    };

    const dateBodyTemplate = (rowData:any) => {
        return (
            <>
                <span>{moment(rowData.dateReq).format("M/D/YY")}</span>
        </>
    );
    };
    const bookNowTemplate = (rowData:any) =>{
        return <><span>{formatMoney(rowData['bookNowRate'])}</span></>;
    };
    const hotloadTemplate = (rowData:any) =>{
        return <><span><input type={"checkbox"} disabled={true} checked={rowData['hotLoad']}/></span></>;
    }
    const sendToBoardTemplate = (rowData:any) =>{
        return <><span><input type={"checkbox"} disabled={true} checked={rowData['sendToBoard']}/></span></>;
    };
    const mapTemplate = (rowData:any) =>{
        return <><IonButton href={rowData['mapLink']} target="_blank" color="secondary"><IonIcon icon={map}></IonIcon></IonButton></>
    };
    const exportCSV = (selectionOnly:any) => {
        let item:any = dt.current;
        item.exportCSV({ selectionOnly });
    };
    const cols:any[]= [
        { title: "ORDER #", dataKey: "orderID" },
        { title: "CITY", dataKey: "city" },
        { title: "STATE", dataKey: "state" },
        { title: "HOT LOAD", dataKey: "hotLoad" },
        { title: "Tarped", dataKey: "tarpRequired" },
        { title: "MILES", dataKey: "miles" },
        { title: "BOOK NOW RATE", dataKey: "bookNowRate" }
    ];
    const exportPdf = () => {
        const exportColumns = cols;
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc:any = new jsPDF.default("landscape", "px");
                doc.autoTable(exportColumns, data);
                doc.save('products.pdf');
            })
        })
    };

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            let dataMapped:any[] = data.map(i=>{
                return {orderID:i.orderID, city:i.city,state:i.state,hotLoad:i.hotLoad, miles:i.miles,bookNowRate:i.bookNowRate}
            });
            dataMapped.unshift({});
            const worksheet = xlsx.utils.json_to_sheet(dataMapped,{  skipHeader: true });
            xlsx.utils.sheet_add_aoa(worksheet, [["ORDER #","CITY","STATE","HOT LOAD","MILES","BOOK NOW RATE" ]]); //heading: array of arrays
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'products');
        });
    };
    const orderTemplate = (rowData:any)=>{
        if(rowData.shipWeight > 40000)
            return <><span style={{fontWeight:800}}>{rowData.orderID}</span></>
        return <>{rowData.orderID}</>
    };
    const saveAsExcelFile = (buffer:any, fileName:any) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    };
    const header = (
        <div className="p-d-flex p-ai-center export-buttons">
        <Button type="button" icon="pi pi-file-o" onClick={() => exportCSV(false)} className="p-mr-2" data-pr-tooltip="CSV" />
    <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />
    <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" />
        </div>
);
    const tarpedTemplate = (rowData:any) =>{
        return <><span><input type={"checkbox"} disabled={true} checked={rowData['tarpRequired']}/></span></>;
    }
    const dateFilter = <Calendar value={selectedDate} onChange={onDateChange} dateFormat="mm/dd/yy" className="p-column-filter" placeholder="Registration Date"/>;
    return <>
        <DataTable ref={dt} value={data} header={header} globalFilter={globalFilter} paginatorTemplate="CurrentPageReport RowsPerPageDropdown"
    currentPageReportTemplate="{totalRecords} total trucks" paginator >
        <Column field="orderID" header="Order #" sortable filter body={orderTemplate}></Column>
        <Column field="dateReq" header="Order Date" sortable filter body={dateBodyTemplate} filterElement={dateFilter} filterFunction={filterDate}></Column>
        <Column field="city" header="City" sortable filter></Column>
        <Column field="state" header="State" sortable filter></Column>
        <Column field="miles" header="Miles" sortable filter></Column>
        <Column field="mapLink" header="Map"  body={mapTemplate}></Column>
        <Column field="bookNowRate" header="BOOK NOW" sortable filter body={bookNowTemplate} ></Column>
        <Column field="hotLoad" header="Hot Load?" sortable   body={hotloadTemplate}></Column>
        <Column field="tarpRequired" header="Tarped?" sortable   body={tarpedTemplate}></Column>
        <Column field="sendToBoard" header="Send To Board?" sortable   body={sendToBoardTemplate}></Column>
        <Column field="actions" header="Actions"  body={actionsBody}></Column>
        </DataTable>
        </>;
};